export const translations = {
  es: {
    title: "Política de Privacidad",
    paragraph1: "KOLIBIRD APP en cumplimiento de lo establecido en el Reglamento General de Protección de datos (RGPD 2016/679), informa a los usuarios de la página web https://kolibird.app/:",
    paragraph2: "¿Quién es el responsable del tratamiento de sus datos? ",
    paragraph3: "Los datos que nos faciliten el usuario a través de los formularios de esta página web se incorporarán a tratamientos cuyo responsable es KOLIBIRD APP. Puede contactar llamando al 675493668 o a través del correo electrónico hi@kolibird.app",
    paragraph4: "¿Qué categoría de datos tratamos? ",
    paragraph5: "Los datos que se recaban se refieren a la categoría de datos identificativos, como pueden ser: Nombre y Apellidos, Teléfono, Dirección Postal, Empresa, Correo electrónico, así como la dirección IP desde donde accede al formulario de recogida de datos.",
    paragraph6: "¿Cuánto guardamos sus datos?",
    paragraph7: "Conservaremos sus datos personales desde que nos dé su consentimiento hasta que lo revoque o bien solicite la limitación del tratamiento. En tales casos, mantendremos sus datos de manera bloqueada durante los plazos legalmente exigidos.",
    paragraph8: "¿Realizamos perfiles con sus datos?",
    paragraph9: "Con los datos introducidos por el usuario no se tomarán decisiones automatizadas ni se realizarán perfiles.",
    paragraph10: "¿Qué legitima el tratamiento de sus datos?",
    paragraph11: "La legitimación para el tratamiento de sus datos es el consentimiento expreso otorgado mediante un acto positivo y afirmativo (rellenar el formulario correspondiente y marcar la casilla de aceptación de esta política) en el momento de facilitarnos sus datos personales.",
    paragraph12: "Al rellenar los formularios, marcar la casilla “Acepto la Política de Privacidad” y hacer clic para enviar los datos, o al remitir correos electrónicos a la Empresa a través de las cuentas habilitadas al efecto, el Usuario manifiesta haber leído y aceptado expresamente la presente política de privacidad, y otorga su consentimiento inequívoco y expreso al tratamiento de sus datos personales conforme a las finalidades indicadas.",
    paragraph13: "Así mismo, y de acuerdo con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, completando el formulario de recogida de datos y marcando la correspondiente casilla “Quiero recibir información con las novedades de Kolibird en mi mail” o al darse de alta en nuestro servicio de Newsletter y marcar la correspondiente casilla de “He leído y acepto la política de privacidad” está otorgando el consentimiento expreso para enviarle a su dirección de correo electrónico, teléfono, fax u otro medio electrónico en envío de información acerca de la Empresa.",
    paragraph14: "El usuario deberá cumplimentar los campos marcados con * para poder enviar los formularios cumplimentados, si no se cumplimentan los campos indicados el usuario no podrá enviarlos.",
    paragraph15: "¿A quién cedemos sus datos?",
    paragraph16: "No se prevén cesiones de datos ni transferencias internacionales de sus datos, a excepción de las autorizadas por la legislación fiscal, mercantil y de telecomunicaciones, así como en aquellos casos en los que una autoridad judicial nos lo requiera.",
    paragraph17: "¿Qué terceros prestan servicios en esta web?",
    paragraph18: "Para el desarrollo de esta página web se utilizan los siguientes proveedores de servicios:",
    paragraph19: "Hosting: Los servidores donde está alojada nuestra página web están ubicados en la UE. Utiliza protocolos seguros con lo que la comunicación de datos se hace a través de canal cifrado.",
    paragraph20: "Newsletter: Le informamos que nuestra newsletter se manda con la herramienta MAILCHIMP cuyos servidores están alojados en EEUU. Mailchimp acata y procesa los datos conforme con las cláusulas tipo de protección de datos aprobadas por la Comisión Europea.",
    paragraph21: "¿Qué derechos tiene y como ejercerlos?",
    paragraph22: "El usuario tiene derecho a:",
    paragraph23: "Acceder a sus datos personales.",
    paragraph24: "Solicitar la rectificación de los datos personales que sean inexactos.",
    paragraph25: "Solicitar su supresión cuando, entre otros motivos, ya no sean necesarios para los fines que fueron recogidos.",
    paragraph26: "Solicitar la limitación de su tratamiento, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de reclamaciones.",
    paragraph27: "Pedir la portabilidad de sus datos.",
    paragraph28: "Por motivos relacionados con su situación particular, podrá oponerse al tratamiento de los mismos y KOLIBIRD APP dejará de tratarlos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.",
    paragraph29: "El usuario podrá revocar los consentimientos prestados en cualquier momento",
    paragraph30: "El usuario podrá ejercitar sus derechos a través de correo electrónico hi@kolibird.app, adjuntando fotocopia del DNI e indicando el derecho que ejercita.",
    paragraph31: "El usuario también podrá presentar una reclamación ante la Agencia Española de Protección de Datos.",
    paragraph32: "KOLIBIRD APP tiene implantadas todas las medidas de seguridad, a fin de garantizar la confidencialidad en el almacenamiento y tratamiento de los datos personales, así como evitar accesos por parte de terceros no autorizados.",
    paragraph33: "¿Cómo funcionan nuestras redes sociales?",
    paragraph34: "A través de nuestras redes sociales puedes compartir nuestros contenidos o dejarnos tus comentarios, fotos o vídeos, siempre que sean del interés para toda la comunidad.",
    paragraph35: "Nuestras redes sociales están constantemente supervisadas con el fin de atender vuestras preguntas y comentarios en el menor tiempo posible.",
    paragraph36: "Queremos que sea un espacio del que todos disfrutemos, por lo que es importante que entre todos respetemos unas normas de convivencia:",
    paragraph37: "Tú eres el responsable de los contenidos que publiques, no caigas en la violación de cualquier derecho, ley o reglamento.",
    paragraph38: "Cuida la redacción y el tono, evita publicar cualquier información abusiva, difamatoria u obscena.",
    paragraph39: "Respeta el derecho a la privacidad, no hagas comentarios ilícitos, ofensivos o discriminatorios sobre terceros.",
    paragraph40: "Evita comentarios fraudulentos, engañosos o que puedan inducir al error.",
    paragraph41: "No permitimos el spam, ni los mensajes comerciales o publicitarios, no queremos mensajes repetitivos.",
    paragraph42: "No mantendremos conversaciones con perfiles que podamos considerar como falsos.",
    paragraph43: "Por favor, ten en cuenta que el contenido publicado por los seguidores en nuestras páginas no necesariamente refleja el punto de vista de nuestra empresa.",
    paragraph44: "Si tus comentarios no cumplen las ‘Normas de la casa’, podrán ser eliminados, ocultados o bloqueados. Cualquiera que publique contenidos no permitidos será bloqueado de la comunidad.",
    paragraph45: "Nos reservamos el derecho a modificar estas normas en cualquier momento y sin previo aviso."
  },
  en: {
    title: "Legal Notice",
    paragraph1: "KOLIBIRD APP in compliance with the provisions of the General Data Protection Regulation (GDPR 2016/679), informs users of the website https://kolibird.app/:",
    paragraph2: "Who is responsible for the processing of your data?",
    paragraph3: "The data provided to us by the user through the forms on this website will be incorporated into treatments whose responsible is KOLIBIRD APP. You can contact them by calling 675493668 or by email at hi@kolibird.app",
    paragraph4: "What category of data do we process?",
    paragraph5: "The data collected refers to the category of identifying data, such as: Name and Surname, Telephone, Postal Address, Company, Email, as well as the IP address from which you access the data collection form.",
    paragraph6: "How long do we keep your data?",
    paragraph7: "We will keep your personal data from the time you give us your consent until you either revoke it or request to limit the processing. In such cases, we will keep your data in a blocked form for the legally required periods.",
    paragraph8: "Do we profile your data?",
    paragraph9: "No automated decisions will be made with the data entered by the user and no profiling will be carried out.",
    paragraph10: "What legitimizes the processing of your data?",
    paragraph11: "The legitimacy for the processing of your data is the express consent given by a positive and affirmative act (filling in the corresponding form and checking the box of acceptance of this policy) at the time of providing your personal data",
    paragraph12: "By filling in the forms, ticking the box 'I accept the Privacy Policy' and clicking to send the data, or by sending e-mails to the Company through the accounts provided for this purpose, the User declares to have read and expressly accepted this privacy policy, and gives his/her unequivocal and express consent to the processing of his/her personal data in accordance with the purposes indicated.",
    paragraph13: "Likewise, and in accordance with the provisions of Law 34/2002, of July 11, 2002, on Information Society Services and Electronic Commerce, by completing the data collection form and checking the corresponding box 'I want to receive information about Kolibird news in my email' or by registering in our Newsletter service and checking the corresponding box 'I have read and accept the privacy policy' you are giving your express consent to send you information about the Company to your email address, telephone, fax or other electronic means. ",
    paragraph14: "The user must fill in the fields marked with * in order to send the completed forms, if the indicated fields are not filled in the user will not be able to send them.",
    paragraph15: "To whom do we give your data?",
    paragraph16: "No data transfers or international transfers of your data are foreseen, with the exception of those authorized by tax, commercial and telecommunications legislation, as well as in those cases in which a judicial authority requires us to do so",
    paragraph17: "Which third parties provide services on this website?",
    paragraph18: "The following service providers are used for the development of this website:",
    paragraph19: "Hosting: The servers where our website is hosted are located in the EU. It uses secure protocols so that data communication is done through an encrypted channel.",
    paragraph20: "Newsletter: We inform you that our newsletter is sent with the MAILCHIMP tool whose servers are hosted in the USA. Mailchimp complies with and processes the data in accordance with the standard data protection clauses approved by the European Commission.",
    paragraph21: "What rights do you have and how to exercise them?",
    paragraph22: "The user has the right to:",
    paragraph23: "Access their personal data.", paragraph23: "Access their personal data.", paragraph23: "Access their personal data.",
    paragraph24: "Request the rectification of personal data that are inaccurate.",
    paragraph25: "Request their deletion when, among other reasons, they are no longer necessary for the purposes for which they were collected.",
    paragraph26: "To request the limitation of their processing, in which case they will only be kept for the exercise or defense of claims.",
    paragraph27: "To request the portability of your data.",
    paragraph28: "For reasons related to your particular situation, you may object to the processing of your data and KOLIBIRD APP will cease to process them, except for compelling legitimate reasons, or the exercise or defense of possible claims.",
    paragraph29: "The user may revoke the consents given at any time",
    paragraph30: "The user may exercise his/her rights by sending an e-mail to hi@kolibird.app, attaching a photocopy of his/her ID card and indicating the right he/she is exercising",
    paragraph31: "The user may also file a complaint with the Spanish Data Protection Agency",
    paragraph32: "KOLIBIRD APP has implemented all security measures in order to ensure confidentiality in the storage and processing of personal data, as well as to prevent access by unauthorized third parties.",
    paragraph33: "How do our social networks work?",
    paragraph34: "Through our social networks you can share our content or leave us your comments, photos or videos, as long as they are of interest to the entire community.",
    paragraph35: "Our social networks are constantly monitored in order to respond to your questions and comments in the shortest possible time.",
    paragraph36: "We want it to be a space that we all enjoy, so it is important that we all respect the rules of coexistence:",
    paragraph37: "You are responsible for the contents you publish, do not violate any right, law or regulation",
    paragraph38: "Take care of the wording and tone, avoid publishing any abusive, defamatory or obscene information.",
    paragraph39: "Respect the right to privacy, do not make illicit, offensive or discriminatory comments about third parties.",
    paragraph40: "Avoid fraudulent, misleading or deceptive comments.",
    paragraph41: "We do not allow spam, commercial or advertising messages, we do not want repetitive messages",
    paragraph42: "We will not engage in conversations with profiles that we may consider to be fake.",
    paragraph43: "Please note that the content posted by followers on our pages does not necessarily reflect our company's point of view.",
    paragraph44: "If your comments do not comply with the 'House Rules', they may be deleted, hidden or blocked. Anyone who posts content that is not allowed will be blocked from the community.",
    paragraph45: "We reserve the right to change these rules at any time without notice."
  }
}