import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoES, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/privacyPolicy.js";

const PrivacyPolicyPage = () => {
  return (
    <Layout page="privacy" language="es">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.es.title}</h1>
          <p>{translations.es.paragraph1}</p>
          <p className="bold">{translations.es.paragraph2}</p>
          <p>{translations.es.paragraph3}</p>
          <p className="bold">{translations.es.paragraph4}</p>
          <p>{translations.es.paragraph5}</p>
          <p className="bold">{translations.es.paragraph6}</p>
          <p>{translations.es.paragraph7}</p>
          <p className="bold">{translations.es.paragraph8}</p>
          <p>{translations.es.paragraph9}</p>
          <p className="bold">{translations.es.paragraph10}</p>
          <p>{translations.es.paragraph11}</p>
          <p>{translations.es.paragraph12}</p>
          <p>{translations.es.paragraph13}</p>
          <p>{translations.es.paragraph14}</p>
          <p className="bold">{translations.es.paragraph15}</p>
          <p>{translations.es.paragraph16}</p>
          <p className="bold">{translations.es.paragraph17}</p>
          <p>{translations.es.paragraph18}</p>
          <p>{translations.es.paragraph19}</p>
          <p>{translations.es.paragraph20}</p>
          <p className="bold">{translations.es.paragraph21}</p>
          <p>{translations.es.paragraph22}</p>
          <ul>
            <li>{translations.es.paragraph23}</li>
            <li>{translations.es.paragraph24}</li>
            <li>{translations.es.paragraph25}</li>
            <li>{translations.es.paragraph26}</li>
            <li>{translations.es.paragraph27}</li>
            <li>{translations.es.paragraph28}</li>
            <li>{translations.es.paragraph29}</li>
          </ul>
          <p>{translations.es.paragraph30}</p>
          <p>{translations.es.paragraph31}</p>
          <p>{translations.es.paragraph32}</p>
          <p className="bold">{translations.es.paragraph33}</p>
          <p>{translations.es.paragraph34}</p>
          <p>{translations.es.paragraph35}</p>
          <p>{translations.es.paragraph36}</p>
          <ol>
            <li>{translations.es.paragraph37}</li>
            <li>{translations.es.paragraph38}</li>
            <li>{translations.es.paragraph39}</li>
            <li>{translations.es.paragraph40}</li>
            <li>{translations.es.paragraph41}</li>
            <li>{translations.es.paragraph42}</li>
          </ol>
          <p>{translations.es.paragraph43}</p>
          <p>{translations.es.paragraph44}</p>
          <p>{translations.es.paragraph45}</p>
        </div>
      </main>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const Head = () => (
  <>
    <SeoES />
    <NoIndex />
  </>
);
